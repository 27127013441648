import moment from "moment";
import { requestAllEvents } from "../../../utils/requests/requests";
import { getWeek, isAfter } from "../../../utils/dates/dates";

export const getEvents = async (isWeekEvents) => {
  const result = await requestAllEvents();
  if (isWeekEvents) {
    const thisWeek = getWeek();
    let tmpTitle = "";
    let tmpEvents = [];
    const weekEvents = result.data
      .filter(
        (item) =>
          getWeek(item.date) === thisWeek &&
          moment(item.date).isSameOrAfter(moment(), "day")
      )
      .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    if (weekEvents) {
      tmpTitle = "Cette semaine au Maëly's";
      tmpEvents = weekEvents;
    } else {
      tmpTitle = "Prochainement au Maëly's";
      tmpEvents = result.data
        .filter((item) => moment(item.date).isSameOrAfter(moment(), "day"))
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }
    return { title: tmpTitle, events: tmpEvents };
  }
  const eventsAfterToday = result.data
    .filter((item) => isAfter(item.date))
    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  return eventsAfterToday;
};
