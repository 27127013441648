import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Tooltip, Box, styled, Button } from "@mui/material";
import AdminMain from "../AdminMain/AdminMain";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    flexGap: 6,
    padding: "1rem",
  },
}));

const ButtonLink = styled(Link)(({ theme }) => ({
  padding: "1rem 2rem",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  backdropFilter: "blur(5px)",
  border: "none",
  borderRadius: "5px",
  color: "#fffde7",
  fontSize: "2rem",
  width: "30%",
  fontWeight: 700,
  margin: "1rem auto",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

function AdminHomePage() {
  return (
    <>
      <Helmet>
        <title>Admin - Accueil</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/home" />
      </Helmet>
      <AdminMain>
        <StyledBox>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            title="Gérer toute la partie menu et boisson"
            placement="bottom"
          >
            <ButtonLink to="/admin/menus/drink">
              Gestion des Boissons
            </ButtonLink>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            title="Ajouter, Modifier ou Supprimer un évènement"
            placement="bottom"
          >
            <ButtonLink to="/admin/event">Gestion des Évènements</ButtonLink>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            title="Voir les réservations à venir"
            placement="bottom"
          >
            <ButtonLink to="/admin/reservation">
              Gestion des Réservations
            </ButtonLink>
          </Tooltip>
        </StyledBox>
      </AdminMain>
    </>
  );
}
export default AdminHomePage;
