export const UPDATE_MENUPART_INPUT = "UPDATE_MENUPART_INPUT";
export const GET_ALL_MENUPARTS = "GET_ALL_MENUPARTS";
export const SET_ALL_MENUPARTS = "SET_ALL_MENUPARTS";
export const GET_ONE_MENUPART = "GET_ONE_MENUPART";
export const SET_ONE_MENUPART = "SET_ONE_MENUPART";
export const SET_MENUPART_INFOS = "SET_MENUPART_INFOS";
export const CREATE_MENUPART = "CREATE_MENUPART";
export const UPDATE_MENUPART = "UPDATE_MENUPART";
export const DELETE_MENUPART = "DELETE_MENUPART";
export const RESET_MENUPART = "RESET_MENUPART";
export const RESET_MENUPART_INPUT = "RESET_MENUPART_INPUT";

export function actionUpdateMenupartInput(name, value) {
  return {
    type: UPDATE_MENUPART_INPUT,
    payload: { name, value },
  };
}

export function actionGetAllMenuparts() {
  return { type: GET_ALL_MENUPARTS };
}

export function actionSetAllMenuparts(datas) {
  return { type: SET_ALL_MENUPARTS, payload: datas };
}

export function actionGetOneMenupart() {
  return { type: GET_ONE_MENUPART };
}

export function actionSetOneMenupart(data) {
  return {
    type: SET_ONE_MENUPART,
    payload: data,
  };
}

export function actionSetMenupartInfos(data) {
  return {
    type: SET_MENUPART_INFOS,
    payload: data,
  };
}

export function actionCreateMenupart(data) {
  return { type: CREATE_MENUPART, payload: data };
}

export function actionUpdateMenupart(data) {
  return { type: UPDATE_MENUPART, payload: data };
}

export function actionDeleteMenupart(data) {
  return { type: DELETE_MENUPART, payload: data };
}

export function actionResetMenupart() {
  return { type: RESET_MENUPART };
}

export function actionResetMenupartInput() {
  return { type: RESET_MENUPART_INPUT };
}
