import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { actionOpenSnackbar } from "../../../redux/actions";
import { getReservationsByWeek } from "../../../utils/requests/reservationRequest";
import ResaAccordion from "./ResaAccordion";
import AdminMain from "../AdminMain/AdminMain";

function ResaPage() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [reservations, setReservations] = React.useState([]);
  const [expanded, setExpanded] = useState();

  const getWeekReservations = async () => {
    try {
      const response = await getReservationsByWeek();
      if (response.status === 200) {
        setReservations(response.data);
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          message: "Erreur lors de la récupération des réservations",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getWeekReservations();
  }, []);
  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/event" />
      </Helmet>
      <AdminMain>
        <Box>
          <Typography variant="h2">Gestion des Réservations</Typography>
          <Box
            sx={{
              width: isMobile ? "95%" : "60%",
              margin: "auto",
              padding: "1.5rem",
              backgroundColor: "rgba(17, 17, 17, 0.865)",
              backdropFilter: "blur(4px)",
              borderRadius: "5px",
            }}
          >
            {reservations
              ?.sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((r) => (
                <ResaAccordion
                  key={r.date}
                  date={r.date}
                  expanded={expanded}
                  handleChange={(panel) => (_, isExpanded) =>
                    setExpanded(isExpanded ? panel : false)}
                  reservations={r.reservations}
                  isMobile={isMobile}
                />
              ))}
          </Box>
        </Box>
      </AdminMain>
    </>
  );
}

export default ResaPage;
