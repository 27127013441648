/* eslint-disable camelcase */
import {
  getAllEvents,
  getOneEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  getAllEventsWithReservations,
} from "../../utils/requests/eventRequest";
import * as actions from "../actions";

const eventMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_EVENTS: {
      const response = await getAllEvents();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllEvents(response.data));
      }
      return;
    }

    case actions.GET_ONE_EVENT: {
      const { event } = store.getState();
      const response = await getOneEvent(event.id);
      if (response.status === 200) {
        store.dispatch(actions.actionSetOneEvent(response.data));
        if (response.data.photos[0]) {
          store.dispatch(actions.actionSetPhotoInfos(response.data.photos[0]));
        }
        store.dispatch(actions.actionSetEventInfos(response.data));
      }
      return;
    }

    case actions.CREATE_EVENT: {
      const { photo } = store.getState();
      const eventDatas = action.payload;
      const response = await createEvent(eventDatas);
      if (response.status === 200) {
        if (photo.title !== "") {
          await store.dispatch(
            await actions.actionSetPhotoOwnerId("event_id", response.data.id)
          );
          await store.dispatch(actions.actionCreatePhoto());
        }
        await store.dispatch(actions.actionResetEvent());
        await store.dispatch(actions.actionGetAllEvents());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Evènement enregistré avec succès!",
          })
        );
      } else {
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "error",
            alertMessage: response.error.message,
          })
        );
      }
      return;
    }

    case actions.UPDATE_EVENT: {
      const { photo } = store.getState();
      const { id, ...rest } = action.payload;
      const eventDatas = { ...rest };
      if (photo.id === 0) {
        await store.dispatch(actions.actionCreatePhoto());
      }
      const response = await updateEvent(id, eventDatas);
      if (response.status === 200) {
        await store.dispatch(actions.actionResetEvent());

        await store.dispatch(actions.actionGetAllEvents());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Evènement modifié avec succès!",
          })
        );
      }
      return;
    }

    case actions.DELETE_EVENT: {
      const { photo } = store.getState();
      if (photo.id) {
        await store.dispatch(actions.actionRemovePhotoFromFirebase());
      }
      const response = await deleteEvent(action.payload);
      if (response.status === 204) {
        await store.dispatch(actions.actionResetEvent());

        await store.dispatch(actions.actionGetAllEvents());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Evènement supprimé avec succès!",
          })
        );
      }
      return;
    }

    default:
      next(action);
  }
};

export default eventMiddleware;
