/* eslint-disable camelcase */
import {
  getAllReservations,
  getOneReservation,
  createReservation,
  updateReservation,
  deleteReservation,
} from "../../utils/requests/reservationRequest";
import * as actions from "../actions";

const reservationMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_RESERVATIONS: {
      const response = await getAllReservations();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllReservations(response.data));
      }
      return;
    }

    case actions.GET_ONE_RESERVATION: {
      const { reservation } = store.getState();
      const response = await getOneReservation(reservation.id);
      if (response.status === 200) {
        store.dispatch(actions.actionSetOneReservation(response.data));
        // store.dispatch(actions.actionSetReservationInfos(response.data));
      }
      return;
    }

    case actions.CREATE_RESERVATION: {
      const response = await createReservation(action.payload);
      if (response.status === 200) {
        store.dispatch(actions.actionResetReservation());
      }
      return;
    }

    case actions.UPDATE_RESERVATION: {
      const { reservation } = store.getState();
      const { fistname, lastname, email, phone, event_id } = reservation;
      const reservationDatas = {
        fistname,
        lastname,
        email,
        phone,
        event_id,
      };
      const response = await updateReservation(
        reservation.id,
        reservationDatas
      );
      if (response.status === 200) {
        store.dispatch(actions.actionResetReservation());
      }
      return;
    }

    case actions.DELETE_RESERVATION: {
      const { reservation } = store.getState();
      const response = await deleteReservation(reservation.id);
      if (response.status === 204) {
        store.dispatch(actions.actionResetReservation());
      }
      return;
    }

    default:
      next(action);
  }
};

export default reservationMiddleware;
