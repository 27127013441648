import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import visa from "../../../../Assets/visa.svg";
import mastercard from "../../../../Assets/mastercard-2.svg";
import ticketResto from "../../../../Assets/ticket-restaurant.svg";

function Infos() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        textAlign: "center",
        color: "#fffde7",
        padding: isMobile ? "0.5rem 0" : 0,
      }}
    >
      <Typography variant="h1">Maëly&apos;s Cafe</Typography>
      <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
        Ouvert du Mardi au Samedi
      </Typography>
      <Typography sx={{ margin: "1rem" }}>
        Du Mardi au Samedi : Plats du jour tous les midis
      </Typography>
      <Typography sx={{ margin: "1rem" }}>
        Concerts tous les week-ends
      </Typography>
      <Box>
        <Typography>Moyens de paiement acceptés :</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 5,
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "1rem 0",
            "& img": {
              width: "3rem",
            },
          }}
        >
          <img src={visa} alt="Logo Visa" />
          <img src={mastercard} alt="Logo mastercard" />
        </Box>
      </Box>
      <a href="tel:+33950808715">
        <Typography
          variant="body2"
          sx={{
            "&:hover": {
              textShadow:
                "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
            },
          }}
        >
          Tel : 09 50 80 87 15
        </Typography>
      </a>
      <Box sx={{ margin: "1rem" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2912.093648311182!2d5.890198415916959!3d43.12355887914292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9050d3217b7b1%3A0x322ca2fdc4df8b82!2zTWHDq2x5cyBjYWbDqQ!5e0!3m2!1sfr!2sfr!4v1651473313702!5m2!1sfr!2sfr"
          width="100%"
          height="auto"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>
    </Box>
  );
}

export default Infos;
