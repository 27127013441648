import React from "react";
import { Typography, Box, useMediaQuery, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import Main from "../../common/Main/Main";

const StyledLi = styled("li")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "1rem",
}));

const ItemBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  margin: "0 2rem",
  gap: "1.5rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
    display: "block",
    fontSize: "1rem",
  },
  "& a": {
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginTop: "1rem",
    },
  },
}));
function ContactPage() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <Helmet>
        <title>Maëly&apos;s Café - Nous Contacter</title>
        <meta
          name="description"
          content="All informations to contact the Maely's Cafe bar by email or phone"
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <Main>
        <Typography variant="h2">Nous contacter</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
            width: "90%",
            margin: isMobile ? "0 auto 2rem" : "auto",
            background:
              "linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100% 0.3em repeat-y",
            border: "8px solid #fffde7",
            borderRadius: "5px",
            boxShadow:
              "0px 0px 6px rgba(255, 253, 231, 0.6), inset 0px 0px 6px rgba(255, 253, 231, 0.6), 0px 0px 16px rgba(255, 253, 231, 0.4), inset 0px 0px 16px rgba(255, 253, 231, 0.4), 0px 0px 32px rgba(255, 253, 231, 0.2), inset 0px 0px 32px rgba(255, 253, 231, 0.2)",
          }}
        >
          <Typography variant="h4">
            Pour nous contacter, rien de plus simple !
          </Typography>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledLi>
              <PhoneIcon fontSize="large" />
              <ItemBox>
                <Typography>Par téléphone au :</Typography>
                <a href="tel:+33950808715">
                  <Typography
                    variant="body2"
                    sx={{
                      "&:hover": {
                        textShadow:
                          "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
                      },
                    }}
                  >
                    09 50 80 87 15
                  </Typography>{" "}
                </a>
              </ItemBox>
            </StyledLi>
            <StyledLi>
              <EmailIcon fontSize="large" />
              <ItemBox>
                <Typography>Par email à l&apos;adresse suivante :</Typography>
                <a href="mailto:contact@maelys-cafe.fr">
                  <Typography
                    variant="body2"
                    sx={{
                      "&:hover": {
                        textShadow:
                          "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
                      },
                    }}
                  >
                    contact@maelys-cafe.fr
                  </Typography>
                </a>
              </ItemBox>
            </StyledLi>
          </ul>
        </Box>
      </Main>
    </>
  );
}

export default ContactPage;
