export const GET_ALL_RESERVATIONS = "GET_ALL_RESERVATIONS";
export const SET_ALL_RESERVATIONS = "SET_ALL_RESERVATIONS";
export const GET_ONE_RESERVATION = "GET_ONE_RESERVATION";
export const SET_ONE_RESERVATION = "SET_ONE_RESERVATION";
export const CREATE_RESERVATION = "CREATE_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const DELETE_RESERVATION = "DELETE_RESERVATION";
export const RESET_RESERVATION = "RESET_RESERVATION";

export function actionGetAllReservations() {
  return { type: GET_ALL_RESERVATIONS };
}

export function actionSetAllReservations(datas) {
  return { type: SET_ALL_RESERVATIONS, payload: datas };
}

export function actionGetOneReservation() {
  return { type: GET_ONE_RESERVATION };
}

export function actionSetOneReservation(data) {
  return { type: SET_ONE_RESERVATION, payload: data };
}
export function actionCreateReservation(datas) {
  return { type: CREATE_RESERVATION, payload: datas };
}

export function actionUpdateReservation(datas) {
  return { type: UPDATE_RESERVATION, payload: datas };
}

export function actionDeleteReservation(datas) {
  return { type: DELETE_RESERVATION, payload: datas };
}

export function actionResetReservation() {
  return { type: RESET_RESERVATION };
}
