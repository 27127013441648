/* eslint-disable camelcase */
import { requestLogin, requestCheck } from "../../utils/requests/loginRequest";
import {
  setBearerToken,
  removeBearerToken,
  getLocalBearerToken,
} from "../../utils/requests";
import * as actions from "../actions";
import { APP_MOUNT } from "../actions/system";

const loginMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    // check token
    case APP_MOUNT: {
      next(action);
      const localToken = getLocalBearerToken();
      if (localToken) {
        const response = await requestCheck(localToken);
        if (response.status === 200) {
          const { token } = response.data;
          store.dispatch(actions.actionSetUserInformation(response.data));
          store.dispatch(actions.actionSetIsLogged(true));
          setBearerToken(token);
        } else {
          removeBearerToken();
        }
      }
      return;
    }

    // login action
    case actions.SUBMIT_LOGIN: {
      const { login } = store.getState();

      const response = await requestLogin(login.email, login.password);
      if (response.status === 200) {
        const { token } = response.data;
        store.dispatch(actions.actionResetPassword());
        store.dispatch(actions.actionSetUserInformation(response.data));
        store.dispatch(actions.actionSetIsLogged(true));
        setBearerToken(token);
        store.dispatch(actions.actionSetGoodLogin(true));
      } else {
        store.dispatch(actions.actionSetGoodLogin(false));
        store.dispatch(actions.actionSetIsLogged(false));
      }
      return;
    }

    case actions.LOGOUT:
      removeBearerToken();
      store.dispatch(actions.actionResetUserInformation());
      next(action);
      break;

    default:
      next(action);
  }
};

export default loginMiddleware;
