/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  name: "",
  price: "",
  event_id: 0,
  oneCard: {},
  allCards: [],
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_CARD_INPUT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case actions.SET_ALL_CARDS:
      return {
        ...state,
        allCards: action.payload,
      };
    case actions.SET_ONE_CARD:
      return {
        ...state,
        oneCard: action.payload,
      };
    case actions.SET_CARD_INFOS:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        price: action.payload.price,
        event_id: action.payload.event_id,
      };
    case actions.RESET_CARD_INPUT:
      return {
        ...state,
        name: initialState.name,
        price: initialState.price,
        event_id: initialState.event_id,
      };
    case actions.RESET_CARD:
      return {
        ...state,
        id: initialState.id,
        name: initialState.name,
        price: initialState.price,
        event_id: initialState.event_id,
        oneCard: initialState.oneCard,
        allCards: initialState.allCards,
      };

    default:
      return state;
  }
}

export default reducer;
