export const UPDATE_MEAL_INPUT = "UPDATE_MEAL_INPUT";
export const GET_ALL_MEALS = "GET_ALL_MEALS";
export const SET_ALL_MEALS = "SET_ALL_MEALS";
export const GET_ONE_MEAL = "GET_ONE_MEAL";
export const SET_ONE_MEAL = "SET_ONE_MEAL";
export const SET_MEAL_INFOS = "SET_MEAL_INFOS";
export const CREATE_MENUPART_MEAL = "CREATE_MENUPART_MEAL";
export const UPDATE_MEAL = "UPDATE_MEAL";
export const DELETE_MEAL = "DELETE_MEAL";
export const RESET_MEAL = "RESET_MEAL";
export const INIT_NEW_MEAL = "INIT_NEW_MEAL";

export function actionUpdateMealInput(name, value) {
  return {
    type: UPDATE_MEAL_INPUT,
    payload: { name, value },
  };
}

export function actionGetAllMeals() {
  return { type: GET_ALL_MEALS };
}

export function actionSetAllMeals(datas) {
  return { type: SET_ALL_MEALS, payload: datas };
}

export function actionGetOneMeal() {
  return { type: GET_ONE_MEAL };
}

export function actionSetOneMeal(data) {
  return {
    type: SET_ONE_MEAL,
    payload: data,
  };
}

export function actionSetMealInfos(data) {
  return {
    type: SET_MEAL_INFOS,
    payload: data,
  };
}

export function actionInitNewMeal(data) {
  return { type: INIT_NEW_MEAL, payload: data };
}

export function actionCreateMenupartMeal() {
  return { type: CREATE_MENUPART_MEAL };
}

export function actionUpdateMeal() {
  return { type: UPDATE_MEAL };
}

export function actionDeleteMeal(data) {
  return { type: DELETE_MEAL, payload: data };
}

export function actionResetMeal() {
  return { type: RESET_MEAL };
}
