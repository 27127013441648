import React, { useRef } from "react";
import { Box, keyframes, Button, styled, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import instagram from "../../../../Assets/logolinks/instagram.png";
import logo from "../../../../Assets/logo_maelys.webp";
import burger from "../../../../Assets/menu.png";
import facebook from "../../../../Assets/logolinks/facebook.png";
import ModalMenu from "../ModalMenu/ModalMenu";

const blink = keyframes`
  0%, 9%, 15%, 79%, 83%, 100% {
    border: 2px solid rgba(255, 251, 14, 1);
    filter: drop-shadow(0 0 2px rgba(255, 251, 14, 1))
            drop-shadow(0 0 6px rgba(255, 251, 14, 0.8))
            drop-shadow(0 0 20px rgba(255, 251, 14, 0.4));
  }

  10%, 14%, 80%, 82% {
    border: 2px solid rgba(255, 251, 14, 0.6);
    filter: none;
  }
`;

const StyledBurgerButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "calc(50% - 60px / 2)",
  left: "1rem",
  backgroundColor: "#000",
  border: "none",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const logoStyle = {
  position: "relative",
  display: "block",
  maxWidth: "70%",
  pointerEvents: "none",
  margin: "0.5rem auto",
  borderRadius: "50%",

  animation: `${blink} 3s infinite alternate`,
};

function Header() {
  const buttonOpen = useRef(null);
  const modalElement = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        textAlign: "center",
        marginBottom: isMobile ? 0 : "2rem",
      }}
    >
      <Box className="header-container">
        <StyledBurgerButton
          type="button"
          className="header-menu-burger"
          ref={buttonOpen}
        >
          <img src={burger} alt="burger-menu" />
        </StyledBurgerButton>
        <Box
          sx={{
            display: "none",
            position: "fixed",
            zIndex: "1",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgb(0, 0, 0, 0.8)",
          }}
          ref={modalElement}
        >
          <ModalMenu buttonOpen={buttonOpen} modalElement={modalElement} />
        </Box>
        <Link to="/">
          <Box
            component="img"
            src={logo}
            sx={logoStyle}
            alt="Logo du Maëly's Café"
          />
        </Link>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 5 }}>
          <Link
            to="https://www.facebook.com/Maelys-Caf%C3%A9-201902384059045/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="Logo de Facebook" />
          </Link>
          <Link
            to="https://www.instagram.com/maelys_cafe/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="Logo d'Instagram" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
