import { IconButton, Tooltip } from "@mui/material";
import React from "react";

function GenericIconButton({ tooltipText, onClick, icon }) {
  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      title={tooltipText}
      placement="bottom"
    >
      <IconButton sx={{ color: "#fffde7" }} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default GenericIconButton;
