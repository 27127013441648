import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actionOpenSnackbar } from "../../../../redux/actions";
import { getFrFormat } from "../../../../utils/dates/dates";
import { getOneEvent } from "../../../../utils/requests/eventRequest";
import {
  deleteReservation,
  getOneReservation,
} from "../../../../utils/requests/reservationRequest";
import Main from "../../../common/Main/Main";

function DeleteResaPage() {
  const { reservationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getReservation = async () => {
    setLoading(true);
    if (reservationId) {
      try {
        const response = await getOneReservation(reservationId);
        if (response.status === 200) {
          setReservation(response.data);
          setEventDate(response.data.date);
        }
      } catch (error) {
        dispatch(
          actionOpenSnackbar({
            severity: "error",
            alertMessage:
              "Une erreur s'est produite, la réservation n'a pas pu être trouvée.",
          })
        );
        navigate("/", { replace: true });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getReservation();
  }, [reservationId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await deleteReservation(reservationId, eventDate);
      if (response.status === 204) {
        dispatch(
          actionOpenSnackbar({
            severity: "success",
            alertMessage:
              "Annulation enregistrée avec succès! Un email de confirmation a été envoyé à l'adresse email renseignée.",
          })
        );
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message || "Une erreur s'est produite",
        })
      );
    } finally {
      navigate("/", { replace: true });
      setLoading(false);
    }
  };

  return (
    <Main>
      <Box
        maxWidth="sm"
        sx={{
          margin: "auto",
          padding: "1rem",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(5px)",
        }}
      >
        <Box>
          <Typography variant="h4">Annulation de la réservation</Typography>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              marginBottom: "1rem",
              "& span": {
                fontWeight: 700,
              },
            }}
          >
            Pour le <span>{getFrFormat(eventDate)}</span>
          </Typography>
        </Box>
        <DialogContent sx={{ color: "#fffde7" }}>
          <Stack
            component="form"
            onSubmit={(e) => handleSubmit(e)}
            sx={{ gap: 4 }}
          >
            <Typography variant="body2">
              Êtes-vous sûr de vouloir annuler votre réservation pour la soirée
              du {eventDate ? getFrFormat(eventDate).toLowerCase() : ""} ?
            </Typography>
            <Typography variant="subtitle2">
              *Vos informations personnelles seront aussi supprimées.
            </Typography>
            <Button
              disabled={loading}
              type="submit"
              sx={{
                color: "#fffde7",
                backgroundColor: "rgba(255,253,231,0.2)",
                "&:hover": {
                  textShadow:
                    "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
                },
              }}
            >
              Confirmer l'annulation
            </Button>
          </Stack>
        </DialogContent>
        <Backdrop
          open={loading}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress color="#fffde7" />
        </Backdrop>
      </Box>
    </Main>
  );
}

export default DeleteResaPage;
