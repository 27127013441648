import React, { useState, useEffect, useRef } from "react";
import { Stack, Typography, TextField, Button } from "@mui/material";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { storage } from "../../../firebase";

function FileInput({
  label,
  name,
  handleInputState,
  setEventIsDisabled,
  accept,
  isInputDisabled,
  setLoading,
}) {
  const inputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (fileUpload) {
      setIsDisabled(false);
      if (setEventIsDisabled) {
        setEventIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
      if (setEventIsDisabled) {
        setEventIsDisabled(false);
      }
    }
  }, [fileUpload]);

  const uploadFile = async () => {
    setLoading(true);
    if (fileUpload == null) return;
    setIsDisabled(true);
    const fileName = fileUpload.name + v4();
    const imageRef = ref(storage, `images/${fileName}`);
    await uploadBytes(imageRef, fileUpload);
    getDownloadURL(imageRef).then((url) => {
      handleInputState(fileName, url);
    });
    alert(`${name} enregistrée`);
    setFileUpload(null);
    inputRef.current.value = "";
    setLoading(false);
  };

  return (
    <Stack
      sx={{
        margin: "auto",
        width: "100%",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{label}</Typography>
      <TextField
        size="small"
        color="#fffde7"
        fullWidth
        ref={inputRef}
        type="file"
        onChange={(event) => {
          setFileUpload(event.target.files[0]);
        }}
        accept={accept}
        disabled={isInputDisabled}
        slotProps={{
          input: {
            sx: { fontSize: "0.9rem" },
          },
        }}
      />
      {!isDisabled && (
        <Button
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            background: "transparent",
            padding: "5px 10px",
            border: "2px solid #fff",
            borderRadius: "5px",
            cursor: "pointer",
            color: "#fff",
          }}
          type="button"
          className="fileInput__button"
          onClick={uploadFile}
        >
          Ajouter
        </Button>
      )}
    </Stack>
  );
}

export default FileInput;
