import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Main from "../../common/Main/Main";
import MenuPart from "./MenuPart/MenuPart";
import { requestMenu } from "../../../utils/requests/requests";
import "./cartepage.scss";

function CartePage({ cardType }) {
  const [card, setCard] = useState([]);
  const isFoodCard = cardType === "food";

  const getAllMenus = async () => {
    const result = await requestMenu(isFoodCard ? 1 : 3);
    setCard(result);
  };

  useEffect(() => {
    getAllMenus();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Maëly's Café - ${isFoodCard ? "Notre Carte" : "Nos Boissons"}`}
        </title>
        <meta
          name="description"
          content={`To know the whole menu of ${
            isFoodCard ? "menus" : "drinks"
          } offered by the Maely's Cafe bar`}
        />
        <link rel="canonical" href={isFoodCard ? "/card" : "/drinks"} />
      </Helmet>
      <Main>
        <div className="cartePage__container" key={card.id}>
          <Typography variant="h2">{card.name}</Typography>
          {card.menuparts &&
            card.menuparts
              .sort((a, b) => a.ordinal - b.ordinal)
              .map((menupart, index) => (
                <MenuPart menupart={menupart} key={menupart.id} index={index} />
              ))}
        </div>
      </Main>
    </>
  );
}

export default CartePage;
