import React from "react";
import { Link } from "react-router-dom";
import "./notFoundPage.scss";

function NotFoundPage() {
  return (
    <div className="notFoundPage">
      <div className="notFoundPage__container">
        <h1 className="notFoundPage__title">
          Il n&apos;y a rien à boire et à manger ici ...
        </h1>
        <Link to="/" className="notFoundPage__link">
          Retourne au comptoir
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
