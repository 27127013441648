import apiAxios from "./index";

export async function requestMenu(id) {
  try {
    const response = await apiAxios.get(`/cards/${id}`);
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function requestAllEvents() {
  try {
    const response = await apiAxios.get("/events");
    return response;
  } catch (err) {
    return err.response;
  }
}
