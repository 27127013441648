import apiAxios from "./index";

/**
 * function used to make a http request to get all reservations
 * @returns response with reservations in an array of object
 */
export async function getAllReservations() {
  try {
    const response = await apiAxios.get("/reservations");
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la création de la réservation."
    );
  }
}

/**
 * function used to make a http request to get one reservation
 * @param {string} id id of the reservation you want to get
 * @returns response with reservation in an object
 */
export async function getOneReservation(id) {
  try {
    const response = await apiAxios.get(`/reservations/${id}`);
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la création de la réservation."
    );
  }
}

/**
 * function used to make a http request to create a reservation
 * @param {object} reservationDatas reservation informations (name)
 * @returns response with new reservation in an object
 */
export async function createReservation(reservationDatas) {
  try {
    const response = await apiAxios.post("/reservations", reservationDatas);
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la création de la réservation."
    );
  }
}

/**
 * function used to make a http request to update a reservation
 * @param {string} id id of the reservation you want to update
 * @param {object} reservationDatas reservation informations (name)
 * @returns response with updated reservation in an object
 */
export async function updateReservation(id, reservationDatas) {
  try {
    const response = await apiAxios.patch(
      `/reservations/${id}`,
      reservationDatas
    );
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la création de la réservation."
    );
  }
}

/**
 * function used to make a http request to delete a reservation
 * @param {string} id id of the reservation you want to delete
 * @returns response with an empty object
 */
export async function deleteReservation(id, resaDate) {
  try {
    const response = await apiAxios.delete(`/reservations/${id}`, {
      date: resaDate,
    });
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la création de la réservation."
    );
  }
}

export async function getReservationsByWeek() {
  try {
    const response = await apiAxios.get("/reservations/week");
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la récupération des réservations."
    );
  }
}

export async function getAvailableSeats(date) {
  try {
    const response = await apiAxios.post("/reservations/seat-available", {
      date,
    });
    return response;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        "Une erreur s'est produite lors de la récupération des places disponibles."
    );
  }
}
