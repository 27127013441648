import React, { useState } from "react";
import { Modal, Box, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUpdateMealInput,
  actionUpdateMeal,
  actionResetMeal,
  actionCreateMenupartMeal,
  actionResetMenupartInput,
} from "../../../redux/actions";
import DeleteMealModal from "./DeleteMealModal";
import Button from "../../common/Buttons/Button/Button";

import "./modal.scss";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  backgroundColor: "rgba(94, 94, 94, 0.865)",
  backdropFilter: "blur(2px)",
  color: "#fff",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  padding: "1rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "1rem",
  },
}));

function EditMealModal({ openModal, closeModal, drink }) {
  const dispatch = useDispatch();
  const [openDeleteMealModal, setOpenDeleteMealModal] = useState(false);

  const { id, title, quarterprice, halfprice, literprice, price, description } =
    useSelector((state) => state.meal);

  const handleChange = (key, value) => {
    dispatch(actionUpdateMealInput(key, value));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (id !== 0) {
      dispatch(actionUpdateMeal());
    } else {
      dispatch(actionCreateMenupartMeal());
    }
    closeModal();
  };

  const handleClose = () => {
    dispatch(actionResetMeal());
    dispatch(actionResetMenupartInput());
    closeModal();
  };

  const renderDeleteMealModal = () => {
    return (
      <DeleteMealModal
        openModal={openDeleteMealModal}
        closeModal={() => setOpenDeleteMealModal(false)}
        modalTitle={drink ? "Supprimer la boisson" : "Supprimer le plat"}
      />
    );
  };

  const handleDelete = () => {
    setOpenDeleteMealModal(true);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleClose}>
        <StyledBox>
          <Typography
            id="modal__title"
            variant="h4"
            sx={{ textAlign: "center" }}
          >
            {id !== 0
              ? `Modifier ${!drink ? "le plat" : "la boisson"}`
              : `Ajouter ${!drink ? "un plat" : "une boisson"}`}
          </Typography>
          <form className="modal__form" onSubmit={handleSubmit}>
            <div className="modal__form-info">
              <label className="modal__form-info-name">
                Nom
                <input
                  className="modal__form-info-input"
                  required
                  type="text"
                  value={title}
                  id="name_input"
                  name="title"
                  placeholder="Nom"
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                />
              </label>
              <label className="modal__form-info-price">
                Prix
                <div className="modal__form-info-price-content">
                  <input
                    className="modal__form-input-price"
                    type="text"
                    value={price}
                    id="name_input"
                    name="title"
                    placeholder="Prix"
                    onChange={(event) =>
                      handleChange("price", event.target.value)
                    }
                  />
                  <p className="modal__form-input-price-content">€</p>
                </div>
              </label>
            </div>
            {drink && (
              <div className="modal__form-info">
                <label className="modal__form-info-price">
                  Prix 25cl
                  <div className="modal__form-info-price-content">
                    <input
                      className="modal__form-input-price"
                      type="text"
                      value={quarterprice}
                      id="name_input"
                      name="title"
                      placeholder="Prix"
                      onChange={(event) =>
                        handleChange("quarterprice", event.target.value)
                      }
                    />
                    <p className="modal__form-input-price-content">€</p>
                  </div>
                </label>
                <label className="modal__form-info-price">
                  Prix 50cl
                  <div className="modal__form-info-price-content">
                    <input
                      className="modal__form-input-price"
                      type="text"
                      value={halfprice}
                      id="name_input"
                      name="title"
                      placeholder="Prix"
                      onChange={(event) =>
                        handleChange("halfprice", event.target.value)
                      }
                    />
                    <p className="modal__form-input-price-content">€</p>
                  </div>
                </label>
                <label className="modal__form-info-price">
                  Prix 1L
                  <div className="modal__form-info-price-content">
                    <input
                      className="modal__form-input-price"
                      type="text"
                      value={literprice}
                      id="name_input"
                      name="title"
                      placeholder="Prix"
                      onChange={(event) =>
                        handleChange("literprice", event.target.value)
                      }
                    />
                    <p className="modal__form-input-price-content">€</p>
                  </div>
                </label>
              </div>
            )}
            <label className="modal__form-label">
              Description
              <textarea
                className="modal__form-textarea"
                name="description"
                placeholder="Description"
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
                value={description}
              />
            </label>
            <div className="modal__form-button">
              {id !== 0 && (
                <Button
                  classname="normal-button cancel modal__form-button-button"
                  type="button"
                  name="Supprimer"
                  onClick={handleDelete}
                />
              )}
              <Button
                classname="normal-button cancel modal__form-button-button"
                type="button"
                name="Annuler"
                onClick={handleClose}
              />
              <Button
                classname="normal-button validate modal__form-button-button"
                type="submit"
                name={id !== 0 ? "Modifier" : "Ajouter"}
              />
            </div>
          </form>
        </StyledBox>
      </Modal>
      {renderDeleteMealModal()}
    </>
  );
}
export default EditMealModal;
