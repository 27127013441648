import React from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import "./article.scss";

function Article({
  title,
  price,
  quarterprice,
  halfprice,
  literprice,
  description,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        flex: 4,
        margin: isMobile ? 0 : "0.5rem",
        padding: isMobile ? 0 : "0 0.5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: !description ? "flex-end" : "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "70%",
        }}
      >
        <Typography variant="h3">{title}</Typography>
        {description && (
          <Typography variant="subtitle1">{description}</Typography>
        )}
      </Box>
      <div className="article-item__price-container">
        {!halfprice ? (
          <Typography variant="h3">{price} €</Typography>
        ) : (
          <div className="article-item__price-container-double-price">
            {quarterprice && (
              <div className="article-item__price-container-double-price--price">
                <Typography variant="subtitle1">25cl</Typography>
                <Typography variant="h3">{quarterprice} €</Typography>
              </div>
            )}
            {halfprice && (
              <div className="article-item__price-container-double-price--price">
                <Typography variant="subtitle1">50cl</Typography>
                <Typography variant="h3">{halfprice} €</Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}
export default Article;
