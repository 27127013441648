/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  title: "",
  url: "",
  album_id: 0,
  event_id: 0,
  menupart_id: 0,
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PHOTO_INFOS:
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
        url: action.payload.url,
        album_id:
          action.payload.album_id !== 0
            ? action.payload.album_id
            : initialState.album_id,
        event_id:
          action.payload.event_id !== 0
            ? action.payload.event_id
            : initialState.event_id,
        menupart_id:
          action.payload.menupart_id !== 0
            ? action.payload.menupart_id
            : initialState.menupart_id,
      };

    case actions.SET_PHOTO_OWNER_ID:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case actions.RESET_PHOTO:
      return {
        ...state,
        id: initialState.id,
        title: initialState.title,
        url: initialState.url,
        album_id: initialState.album_id,
        event_id: initialState.event_id,
        menupart_id: initialState.menupart_id,
      };
    default:
      return state;
  }
}

export default reducer;
