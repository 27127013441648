import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminMain from "../AdminMain/AdminMain";
import {
  actionSubmitLogin,
  actionUpdateLoginInput,
} from "../../../redux/actions";
import "./loginpage.scss";

function LoginPage() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const dispatch = useDispatch();

  const { email, password, goodLogin, isLogged } = useSelector(
    (state) => state.login
  );

  useEffect(() => {
    if (email.trim() === "" || password.trim() === "") {
      if (!isButtonDisabled) {
        setIsButtonDisabled(true);
      }
    } else if (isButtonDisabled) {
      setIsButtonDisabled(false);
    }

    if (!goodLogin) {
      setShowMessage(true);
    }
  }, [email, password, goodLogin]);

  if (isLogged) {
    return <Navigate to="/admin/home" exact replace />;
  }

  const onInputChange = (name, value) => {
    dispatch(actionUpdateLoginInput(name, value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(actionSubmitLogin());
  };
  return (
    <>
      <Helmet>
        <title>Admin - Login</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/login" />
      </Helmet>
      <AdminMain>
        <form
          action="post"
          className="login__container"
          onSubmit={handleSubmit}
        >
          <p>Email</p>
          <input
            type="email"
            label="email"
            placeholder="Votre email..."
            className="login__container-inputText"
            value={email}
            onChange={(event) => onInputChange("email", event.target.value)}
          />
          <p>Mot de passe</p>
          <input
            type="password"
            label="password"
            placeholder="Votre mot de passe..."
            className="login__container-inputText"
            value={password}
            onChange={(event) => onInputChange("password", event.target.value)}
          />
          {showMessage && <p>Email ou mot de passe incorrect</p>}
          <div className="login__container-buttondiv">
            <button
              disabled={isButtonDisabled}
              style={{
                color: isButtonDisabled ? "black" : "white",
                backgroundColor: isButtonDisabled ? "gray" : "black",
              }}
              type="submit"
              className="login__container-button"
            >
              Se connecter
            </button>
          </div>
        </form>
      </AdminMain>
    </>
  );
}
export default LoginPage;
