import * as yup from "yup";
import moment from "moment";

export const getBookingSchema = (availableSeats) => {
  return yup.object({
    date: yup
      .string()
      .nullable() // Autoriser null initialement
      .required("La date est requise.")
      .test("is-date", "La date doit être valide", (value) => {
        return value ? moment(value).isValid() : false;
      }),
    firstname: yup.string().required("Votre prénom est requis."),
    lastname: yup.string().required("Votre nom est requis."),
    email: yup
      .string()
      .email("L'email saisi n'est pas valide.")
      .required("Votre email est requis."),
    phone: yup
      .string()
      .matches(
        /^(?:(?:\+33|0)[1-5|6|7])(?:[ .-]?\d{2}){4}$/,
        "Le numéro de téléphone n'est pas dans le bon format."
      )
      .required("Votre numéro de téléphone est requis."),
    seat: yup
      .number()
      .required("Le nombre de personnes est requis.")
      .max(20, "Le nombre de personnes par réservation est limité à 20.")
      .test(
        "is-seat-available",
        `Le nombre de places demandées dépasse les ${availableSeats} places disponibles.`,
        (value) => {
          return value <= availableSeats;
        }
      ),
  });
};

export const photoSchema = yup.object({
  id: yup.number().integer("L'ID doit être un entier"), // Si vous voulez forcer un ID positif
  title: yup
    .string()
    .required("Le titre est requis")
    .min(3, "Le titre doit contenir au moins 3 caractères")
    .max(100, "Le titre doit contenir au maximum 100 caractères"),
  url: yup.string().required("L'URL est requise").url("L'URL doit être valide"),
});

// Schéma principal de l'événement
export const EventSchema = yup
  .object({
    title: yup.string().required("Le titre est requis."),
    date: yup
      .string()
      .required("La date est obligatoire")
      .test("is-date", "La date doit être valide", (value) => {
        return moment(value, moment.ISO_8601, true).isValid();
      }),
    description: yup.string(),
  })
  .required();

export const MenuPartSchema = yup
  .object({
    name: yup.string().required("Le Nom est requis."),
    ordinal: yup
      .number()
      .required("Le nombre de personnes est requis.")
      .min(1, "L'ordre est minimum de 1."),
    description: yup.string(),
  })
  .required();
