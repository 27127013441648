export const UPDATE_EVENT_INPUT = "UPDATE_EVENT_INPUT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const SET_ALL_EVENTS = "SET_ALL_EVENTS";
export const GET_ONE_EVENT = "GET_ONE_EVENT";
export const SET_ONE_EVENT = "SET_ONE_EVENT";
export const SET_EVENT_INFOS = "SET_EVENT_INFOS";
export const CREATE_EVENT = "CREATE_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const RESET_EVENT = "RESET_EVENT";
export const GET_ALL_EVENTS_WITH_RESERVATIONS =
  "GET_ALL_EVENTS_WITH_RESERVATIONS";

export function actionUpdateEventInput(name, value) {
  return {
    type: UPDATE_EVENT_INPUT,
    payload: { name, value },
  };
}

export function actionGetAllEvents() {
  return { type: GET_ALL_EVENTS };
}

export function actionSetAllEvents(datas) {
  return { type: SET_ALL_EVENTS, payload: datas };
}

export function actionGetOneEvent() {
  return { type: GET_ONE_EVENT };
}

export function actionSetOneEvent(data) {
  return { type: SET_ONE_EVENT, payload: data };
}

export function actionSetEventInfos(data) {
  return {
    type: SET_EVENT_INFOS,
    payload: data,
  };
}

export function actionCreateEvent(data) {
  return { type: CREATE_EVENT, payload: data };
}

export function actionUpdateEvent(data) {
  return { type: UPDATE_EVENT, payload: data };
}

export function actionDeleteEvent(data) {
  return { type: DELETE_EVENT, payload: data };
}

export function actionResetEvent() {
  return { type: RESET_EVENT };
}

export function actionGetAllEventsWithReservations() {
  return { type: GET_ALL_EVENTS_WITH_RESERVATIONS };
}
