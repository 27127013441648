import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import Card from "../components/AdminComponents/MenuPage/Card/Card";
import { actionUpdateCardInput, actionGetOneCard } from "../redux/actions";

function CardContainer() {
  const dispatch = useDispatch();
  const { allMenuparts } = useSelector((state) => state.menupart);

  useEffect(() => {
    dispatch(actionUpdateCardInput("id", 3));
    dispatch(actionGetOneCard());
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin - Menus</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/menus/drink" />
      </Helmet>
      <Card allMenuparts={allMenuparts} drink />
    </>
  );
}

export default CardContainer;
