import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, styled } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { getAllEventsWithReservations } from "../../../utils/requests/eventRequest";
import {
  actionSetAllEvents,
  actionRemovePhotoFromFirebase,
} from "../../../redux/actions";
import AdminMain from "../AdminMain/AdminMain";
import EventModal from "../Modals/EventModal";
import ReservationModal from "../Modals/ReservationModal";
import EventAccordion from "./EventAccordion";

const StyledButton = styled("button")(({ theme }) => ({
  padding: "0.4rem 0.6rem",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  border: "none",
  backgroundColor: "#fffde7",
  color: "#000",
  borderRadius: "20px",
  fontWeight: "bold",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
    fontSize: "1rem",
  },
}));
function EventPage() {
  const dispatch = useDispatch();
  const { allEvents } = useSelector((state) => state.event);
  const [reservations, setReservations] = useState([]);
  const [expanded, setExpanded] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [openReservationModal, setOpenReservationModal] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const fetchEvents = async () => {
    const response = await getAllEventsWithReservations();
    if (response.status === 200) {
      dispatch(actionSetAllEvents(response.data.map((d) => d.events[0])));
      setReservations(response.data.flatMap((d) => d.reservations || []));
    }
  };

  const handleDeletePhoto = async () => {
    await dispatch(actionRemovePhotoFromFirebase());
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleModalClose = () => {
    setOpenEventModal(false);
    setSelectedEvent(null);
    setOpenReservationModal(false);
    setSelectedReservations(null);
  };

  return (
    <>
      <Helmet>
        <title>Admin - Event</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="/admin/event" />
      </Helmet>
      <AdminMain>
        <Box>
          <Typography variant="h2">Gestion des Évènements</Typography>
          <Box
            sx={{
              width: isMobile ? "95%" : "60%",
              margin: "auto",
              padding: "1.5rem",
              backgroundColor: "rgba(17, 17, 17, 0.865)",
              backdropFilter: "blur(4px)",
              borderRadius: "5px",
            }}
          >
            <StyledButton onClick={() => setOpenEventModal(true)}>
              <Add />
              <span>Ajouter un Evènement</span>
            </StyledButton>
            {allEvents
              ?.sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((event) => (
                <EventAccordion
                  key={event?.id}
                  event={event}
                  expanded={expanded}
                  handleChange={(panel) => (_, isExpanded) =>
                    setExpanded(isExpanded ? panel : false)}
                  reservations={reservations.filter(
                    (r) =>
                      moment(r.date).format("YYYY-MM-DD") ===
                      moment(event.date).format("YYYY-MM-DD")
                  )}
                  isMobile={isMobile}
                  onEdit={() => {
                    setSelectedEvent(event);
                    setOpenEventModal(true);
                  }}
                  onViewReservations={() => {
                    setSelectedReservations(
                      reservations.filter(
                        (r) =>
                          moment(r.date).format("YYYY-MM-DD") ===
                          moment(event.date).format("YYYY-MM-DD")
                      )
                    );
                    setOpenReservationModal(true);
                  }}
                />
              ))}
          </Box>
        </Box>
        <EventModal
          open={openEventModal}
          event={selectedEvent}
          onClose={handleModalClose}
          handleDeletePhoto={handleDeletePhoto}
        />
        <ReservationModal
          open={openReservationModal}
          reservations={selectedReservations}
          onClose={handleModalClose}
        />
      </AdminMain>
    </>
  );
}

export default EventPage;
