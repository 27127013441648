export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

/**
 * action called to change message state
 * @param {string} message wich message to change
 * @param {boolean} bool value of change
 * @returns action TOGGLE_MESSAGE
 */
export function actionOpenSnackbar(data) {
  return {
    type: OPEN_SNACKBAR,
    payload: data,
  };
}
export function actionCloseSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  };
}
