import React from "react";
import {
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Button,
} from "@mui/material";

function ConfirmModal({
  open,
  onClose,
  handleDelete,
  title,
  message,
  warningMessage,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.8)",
          backdropFilter: "blur(5px)",
          position: "relative",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
          height: "30dvh",
        },
      }}
    >
      <DialogContent>
        <Typography variant="h3" sx={{ mb: "3rem" }}>
          {title}
        </Typography>
        <Box>
          <Typography variant="body2" sx={{ mb: "1rem" }}>
            {message}
          </Typography>
          <Divider />
          {warningMessage && (
            <Typography variant="subtitle2" sx={{ mb: "1rem" }}>
              {warningMessage}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          "& .MuiButtonBase-root": {
            width: "10rem",
          },
        }}
      >
        <Button
          onClick={onClose}
          sx={{ backgroundColor: "#fffde7", color: "#000" }}
          variant="contained"
        >
          Annuler
        </Button>
        <Button
          onClick={handleDelete}
          color="error"
          variant="contained"
          sx={{ marginLeft: "1rem" }}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
