import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import {
  Tooltip,
  Typography,
  Button,
  styled,
  Box,
  useMediaQuery,
} from "@mui/material";
import { KeyboardReturn } from "@mui/icons-material";
import { actionLogout, actionCloseSnackbar } from "../../../redux/actions";
import SnackbarComponent from "../../common/SnackbarComponent";

import "./adminmain.scss";

const ReturnButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  margin: "1rem",
  padding: "1rem",
  backgroundColor: "transparent",
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1.5rem",
  fontWeight: "bold",
  alignItems: "center",
  "&:hover": {
    textShadow:
      "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
  },
}));
const DisconnectButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "0",
  right: "0",
  margin: "1.5rem",
  padding: "0.5rem",
  color: "#fffde7",
  fontFamily: "Dosis",
  fontSize: "1rem",
  fontWeight: "bold",
  backgroundColor: theme.palette.error.main,
  backdropFilter: "blur(4px)",
  zIndex: 100,
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

function Main({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showReturnButton, setReturnButton] = useState(false);
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.login.isLogged);
  const { open, onClose, severity, alertMessage } = useSelector(
    (state) => state.message
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleCloseSnackbar = () => {
    dispatch(actionCloseSnackbar());
  };

  useEffect(() => {
    if (location.pathname !== "/admin/home") {
      setReturnButton(true);
    } else {
      setReturnButton(false);
    }
  }, [location]);

  const handleClickDisconnect = () => {
    dispatch(actionLogout(false));
  };

  const handleReturnClick = () => {
    if (location.pathname === "/login") {
      return navigate("/", { replace: true });
    }
    return navigate("/admin/home", { replace: true });
  };

  return (
    <main className="admin__main">
      <Box>
        {showReturnButton && (
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -30],
                    },
                  },
                ],
              },
            }}
            title={
              location.pathname === "/login"
                ? "Retourner  la page d'accueil"
                : "Retourner au menu principal"
            }
          >
            <ReturnButton
              onClick={handleReturnClick}
              startIcon={<KeyboardReturn style={{ fontSize: "1.5rem" }} />}
            >
              Retour
            </ReturnButton>
          </Tooltip>
        )}
        <Typography
          variant="h1"
          sx={{
            fontSize: "3rem",
            marginTop: isMobile ? "5rem" : "1rem",
          }}
        >
          Page Admin
        </Typography>
        {isLogged && (
          <DisconnectButton onClick={handleClickDisconnect}>
            Déconnexion
          </DisconnectButton>
        )}
      </Box>
      {children}
      <SnackbarComponent
        open={open}
        onClose={handleCloseSnackbar}
        severity={severity}
        alertMessage={alertMessage}
      />
    </main>
  );
}
export default Main;
