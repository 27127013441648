import React, { useState, useCallback } from "react";
import {
  Box,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add, ExpandMore, Edit, Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import MenupartModal from "../../Modals/MenupartModal";
import EditMealModal from "../../Modals/EditMealModal";
import DeleteMealModal from "../../Modals/DeleteMealModal";
import GenericIconButton from "../../../common/Buttons/IconButton/GenericIconButton";
import {
  actionSetMenupartInfos,
  actionSetPhotoInfos,
  actionSetMealInfos,
  actionInitNewMeal,
  actionDeleteMeal,
  actionOpenSnackbar,
} from "../../../../redux/actions";
import ConfirmModal from "../../Modals/ConfirmModal";

const StyledButton = styled("button")(({ theme }) => ({
  padding: "0.4rem 0.6rem",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  border: "none",
  backgroundColor: "#fffde7",
  color: "#000",
  borderRadius: "20px",
  fontWeight: "bold",
  marginBottom: "1rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    textAlign: "center",
    fontSize: "1rem",
  },
}));

function Card({ allMenuparts, drink }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [openMenupartModal, setOpenMenupartModal] = useState(false);
  const [selectedMenuPart, setSelectedMenuPart] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [openEditMealModal, setOpenEditMealModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [expanded, setExpanded] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDeleteMeal = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(actionDeleteMeal(selectedMeal.id));
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      setOpenConfirmModal(false);
      setLoading(false);
    }
  }, [selectedMeal]);

  const renderEditMealModal = () => {
    return (
      <EditMealModal
        openModal={openEditMealModal}
        closeModal={() => setOpenEditMealModal(false)}
        drink={drink}
      />
    );
  };

  const handleOpenModal = (event, modal, item = null) => {
    switch (modal) {
      case "createMenupart":
      case "editMenupart":
        if (item) {
          setSelectedMenuPart(item);
        }
        setOpenMenupartModal(true);
        break;
      case "createMeal":
        dispatch(actionInitNewMeal(item));
        setOpenEditMealModal(true);
        break;
      case "editMeal":
        dispatch(actionSetMealInfos(item));
        setOpenEditMealModal(true);
        break;
      case "deleteMeal":
        if (item) {
          setSelectedMeal(item);
          setOpenConfirmModal(true);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "60%",
        margin: "0 auto",
        padding: "1.5rem",
        backgroundColor: "rgba(17, 17, 17, 0.865)",
        backdropFilter: "blur(4px)",
        borderRadius: "5px",
      }}
    >
      {renderEditMealModal()}
      <Box sx={{ mb: "2rem" }}>
        <StyledButton
          type="button"
          onClick={(event) => handleOpenModal(event, "createMenupart")}
        >
          <Add />
          <span>Ajouter une catégorie</span>
        </StyledButton>
      </Box>
      {allMenuparts
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((menupart) => (
          <Accordion
            expanded={expanded === menupart.id}
            onChange={handleChange(menupart.id)}
            key={menupart.id}
            elevation={0}
            slotProps={{ transition: { unmountOnExit: true } }}
            sx={{
              color: "#fffde7",
              backgroundColor: "transparent",
              "& .Mui-expanded": {
                backgroundColor: "rgba(94, 94, 94, 0.865)",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: "#fffde7" }} />}
              sx={{
                justifyContent: "space-between",
                "& .MuiAccordionSummary-content": {
                  pr: "2rem",
                  display: "flex",
                  justifyContent: "space-between",
                },
                "& .Mui-expanded": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Typography variant="h3">{menupart.name}</Typography>
              <Box>
                <GenericIconButton
                  tooltipText="Modifier la catégorie"
                  onClick={(event) =>
                    handleOpenModal(event, "editMenupart", menupart)
                  }
                  icon={<Edit />}
                />
                <GenericIconButton
                  tooltipText={
                    drink ? "Ajouter une boisson" : "Ajouter un plat"
                  }
                  onClick={(event) =>
                    handleOpenModal(event, "createMeal", menupart)
                  }
                  icon={<Add />}
                />
              </Box>
            </AccordionSummary>
            {menupart.meals
              .sort((a, b) => a.id - b.id)
              .map((meal) => (
                <AccordionDetails
                  key={meal.id}
                  sx={{
                    backgroundColor: "rgba(94, 94, 94, 0.865)",
                    px: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ flex: 2 }}>{meal.title}</Typography>
                        {!meal.halfprice && (
                          <Typography>{meal.price} €</Typography>
                        )}
                        {drink && meal.halfprice && (
                          <>
                            {meal.quarterprice && (
                              <Box sx={{ mr: "1rem" }}>
                                <Typography>25cl</Typography>
                                <Typography>{meal.quarterprice} €</Typography>
                              </Box>
                            )}
                            {meal.halfprice && (
                              <Box sx={{ ml: "1rem" }}>
                                <Typography>50cl</Typography>
                                <Typography>{meal.halfprice} €</Typography>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                      <Typography variant="subtitle2">
                        {meal.description}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, textAlign: "right" }}>
                      <GenericIconButton
                        tooltipText={
                          drink ? "Modifier la boisson" : "Modifier le plat"
                        }
                        onClick={(event) =>
                          handleOpenModal(event, "editMeal", meal)
                        }
                        icon={<Edit />}
                      />
                      <GenericIconButton
                        tooltipText={
                          drink ? "Supprimer la boisson" : "Supprimer le plat"
                        }
                        onClick={(event) =>
                          handleOpenModal(event, "deleteMeal", meal)
                        }
                        icon={<Delete />}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              ))}
          </Accordion>
        ))}
      <MenupartModal
        openModal={openMenupartModal}
        closeModal={() => {
          setOpenMenupartModal(false);
          setSelectedMenuPart(null);
        }}
        drink={drink}
        menupart={selectedMenuPart}
      />
      <ConfirmModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        handleDelete={handleDeleteMeal}
        title="Supprimer la Boisson"
        message="Êtes-vous sûr de vouloir supprimer cette boisson?"
      />
    </Box>
  );
}

export default Card;
