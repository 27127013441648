import moment from "moment";

export const getTime = (date) => {
  if (moment(date).day() === 0) {
    return moment(date)
      .hour(12)
      .minute(0)
      .second(0)
      .local("fr")
      .format("YYYY-MM-DD HH:mm:ss");
  }
  return moment(date)
    .hour(21)
    .minute(0)
    .second(0)
    .local("fr")
    .format("YYYY-MM-DD HH:mm:ss");
};

export const getTableHour = (date) => {
  if (moment(date).day() === 0) {
    return "*Votre table sera disponible à partir de 12h00 jusqu'à 14h00";
  }
  return "*Votre table sera disponible à partir de 18h00 jusqu'à 20h30";
};
