/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  title: "",
  date: "",
  group: "",
  description: "",
  oneEvent: {},
  allEvents: [],
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_EVENT_INPUT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case actions.SET_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload,
      };
    case actions.SET_ONE_EVENT:
      return {
        ...state,
        oneEvent: action.payload,
      };
    case actions.SET_EVENT_INFOS:
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
        date: action.payload.date,
        group: action.payload.group,
        description: action.payload.description,
      };
    case actions.RESET_EVENT:
      return {
        ...state,
        id: initialState.id,
        title: initialState.title,
        date: initialState.date,
        group: initialState.group,
        description: initialState.description,
        oneEvent: initialState.oneEvent,
        AllEvents: initialState.allEvents,
      };

    default:
      return state;
  }
}

export default reducer;
