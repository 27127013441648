import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Typography,
  CardMedia,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Paper,
} from "@mui/material";
import { ExpandMore, Edit } from "@mui/icons-material";
import moment from "moment";
import GenericIconButton from "../../../common/Buttons/IconButton/GenericIconButton";
import { getFrFormat, getFrTimeFormat } from "../../../../utils/dates/dates";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
  },
}));

function ResaAccordion({
  date,
  expanded,
  handleChange,
  reservations,
  isMobile,
}) {
  const totalResa = reservations.reduce((acc, resa) => acc + resa.seat, 0);
  return (
    <Accordion
      disabled={reservations.length === 0}
      expanded={expanded === date}
      onChange={handleChange(date)}
      key={date}
      sx={{
        color: "#fffde7",
        backgroundColor: "transparent",
        "& .Mui-expanded": {
          backgroundColor: "rgba(94, 94, 94, 0.865)",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: "#fffde7" }} />}
        sx={{
          justifyContent: "space-between",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "space-between",
          },
          "& .Mui-expanded": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <Typography variant="h3">
            {moment(date).format("DD/MM/YYYY")} - total resa: {totalResa}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: "rgba(94, 94, 94, 0.865)", padding: "1rem 0" }}
      >
        <Stack direction={isMobile ? "column" : "row"}>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table sx={{ width: "100%" }} size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Nom</StyledTableCell>
                  {!isMobile && (
                    <>
                      <StyledTableCell align="center">Email</StyledTableCell>
                      <StyledTableCell align="center">
                        Téléphone
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="center">
                    Nombre de personnes
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontSize: "0.5rem" }}>
                {reservations?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell": {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    <StyledTableCell align="center">
                      {row.firstname} {row.lastname}
                    </StyledTableCell>
                    {!isMobile && (
                      <>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.phone}
                        </StyledTableCell>
                      </>
                    )}
                    <StyledTableCell align="center">
                      {row.seat} personnes
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default ResaAccordion;
