import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AppContainer from "./container/AppContainer";
import store from "./redux/store";
import ScrollToTop from "./components/common/ScrollToTop/ScrollToTop";
import theme from "./Theme";
import "./styles/index.scss";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <ScrollToTop />
            <AppContainer />
          </HelmetProvider>
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
