import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Tooltip,
  styled,
  IconButton,
  useMediaQuery,
  Stack,
  TextField,
  Badge,
  CardMedia,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  actionUpdateMenupartInput,
  actionCreateMenupart,
  actionResetMenupartInput,
  actionUpdateMenupart,
  actionSetPhotoInfos,
  actionRemovePhotoFromFirebase,
  actionResetPhoto,
  actionDeleteMenupart,
  actionOpenSnackbar,
} from "../../../redux/actions";
import FileInput from "../FileInput/FileInput";
import ConfirmModal from "./ConfirmModal";
import { MenuPartSchema } from "../../../utils/schemas";

import "./modal.scss";

const style = {
  width: "100%",
  height: "100%",
  backdropFilter: "blur(4px)",
  px: 4,
  pt: 4,
  pb: 2,
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
};

function MenupartModal({ openModal, closeModal, lunch, menupart }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const photo = useSelector((state) => state.photo);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({ resolver: yupResolver(MenuPartSchema), mode: "onTouched" });

  useEffect(() => {
    if (menupart) {
      setValue("id", menupart?.id);
      setValue("name", menupart?.name);
      setValue("ordinal", menupart?.ordinal);
      setValue("description", menupart?.description);
      if (menupart?.photos?.length > 0) {
        dispatch(actionSetPhotoInfos(menupart?.photos[0]));
      }
    } else {
      reset();
      dispatch(actionResetPhoto());
    }
  }, [menupart]);

  useEffect(() => {
    if (photo.title) {
      setIsInputDisabled(true);
    } else {
      setIsInputDisabled(false);
    }
  }, [photo]);

  const handleImageState = (fileName, fileUrl) => {
    const data = {
      id: 0,
      title: fileName,
      url: fileUrl,
      menupart_id: menupart?.id ?? null,
    };
    dispatch(actionSetPhotoInfos(data));
  };

  const handleDeletePhoto = () => {
    dispatch(actionRemovePhotoFromFirebase());
  };

  const handleClose = () => {
    reset();
    closeModal();
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await dispatch(actionDeleteMenupart(menupart?.id));
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      handleClose();
      setOpenConfirmModal(false);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (menupart?.id) {
        dispatch(actionUpdateMenupart(data));
      } else {
        dispatch(actionCreateMenupart(data));
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      handleClose();
      setLoading(false);
    }
    dispatch(actionResetMenupartInput());
    closeModal();
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.8)",
          backdropFilter: "blur(5px)",
          position: "relative",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
          height: "80dvh",
          width: isMobile ? "100%" : "80%",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={(theme) => ({
          zIndex: 2000,
          position: "absolute",
          right: 4,
          top: 4,
          color: "#fffde7",
        })}
      >
        <Close />
      </IconButton>
      <DialogContent sx={style}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {menupart?.id ? "Modifier la catégorie" : "Ajouter une catégorie"}
        </Typography>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            gap: 1,
            pt: "1rem",
            height: "90%",
          }}
        >
          <TextField
            size="small"
            color="#fffde7"
            label="Nom de la catégorie"
            placeholder="Nom de la catégorie"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            {...register("name")}
          />
          <TextField
            size="small"
            color="#fffde7"
            label="Ordre dans le menu"
            type="number"
            placeholder="Ordre dans le menu"
            error={Boolean(errors.ordinal)}
            helperText={errors.ordinal?.message}
            {...register("ordinal")}
          />
          {!lunch && (
            <>
              <TextField
                size="small"
                color="#fffde7"
                label="Description de la catégorie"
                multiline
                placeholder="Description de la catégorie"
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
                {...register("description")}
              />

              {photo?.title ? (
                <Badge
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  sx={{
                    margin: "auto",
                    width: isMobile ? "90%" : "40%",
                    height: isMobile ? "60%" : "50%",
                  }}
                  badgeContent={
                    <IconButton type="button" onClick={handleDeletePhoto}>
                      <Delete fontSize="small" sx={{ color: "#fffde7" }} />
                    </IconButton>
                  }
                >
                  <CardMedia
                    image={photo.url}
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Badge>
              ) : (
                <FileInput
                  name="image"
                  label="Ajouter une image"
                  handleInputState={handleImageState}
                  setEventIsDisabled={setIsDisabled}
                  accept="image/*,.pdf"
                  isInputDisabled={isInputDisabled}
                  setLoading={setLoading}
                />
              )}
            </>
          )}
          <Stack direction="row" justifyContent="space-between" sx={{ gap: 2 }}>
            {menupart?.id && (
              <Button
                sx={{
                  flexGrow: 1,
                  color: "#fffde7",
                  backgroundColor: "rgb(167 17 55)",
                  "&:hover": {
                    transform: "scale(1.1)",
                    color: "rgba(255, 255, 255,1)",
                    fontWeight: "bold",
                  },
                }}
                disabled={isDisabled}
                type="button"
                onClick={() => setOpenConfirmModal(true)}
              >
                Supprimer
              </Button>
            )}
            <Button
              sx={{
                flexGrow: 1,
                color: "#fffde7",
                backgroundColor: "rgba(94, 94, 94, 0.865)",
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "bold",
                  backgroundColor: "rgba(255, 255, 255,1)",
                },
              }}
              disabled={isDisabled}
              type="submit"
            >
              {menupart?.id ? "Modifier" : "Ajouter"}
            </Button>
          </Stack>
        </Stack>
        <Backdrop
          open={loading}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress color="#fffde7" />
        </Backdrop>
      </DialogContent>
      {menupart?.id && (
        <ConfirmModal
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          handleDelete={handleDelete}
          title="Supprimer la catégorie"
          message="Êtes-vous sûr de vouloir supprimer cette catégorie?"
          warningMessage="* Attention, cette action supprimera également toutes les boissons associées."
        />
      )}
    </Dialog>
  );
}
export default MenupartModal;
