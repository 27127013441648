import React from "react";
import { Snackbar, Alert } from "@mui/material";

function SnackbarComponent({
  open,
  onClose,
  severity = "success",
  alertMessage,
}) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={severity} variant="filled" sx={{ width: "100%" }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
