/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  title: "",
  quarterprice: "",
  halfprice: "",
  literprice: "",
  price: "",
  description: "",
  menupartId: 0,
  oneMeal: {},
  allMeals: [],
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_MEAL_INPUT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case actions.SET_ALL_MEALS:
      return {
        ...state,
        allMeals: action.payload,
      };
    case actions.SET_ONE_MEAL:
      return {
        ...state,
        oneMeal: action.payload,
      };
    case actions.SET_MEAL_INFOS:
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
        quarterprice: action.payload.quarterprice,
        halfprice: action.payload.halfprice,
        literprice: action.payload.literprice,
        price: action.payload.price,
        description: action.payload.description,
        menupartId: action.payload.menupart_id,
      };
    case actions.RESET_MEAL:
      return {
        ...state,
        id: initialState.id,
        title: initialState.title,
        quarterprice: initialState.quarterprice,
        halfprice: initialState.halfprice,
        literprice: initialState.literprice,
        price: initialState.price,
        description: initialState.description,
        menupartId: initialState.menupartId,
        oneMeal: initialState.oneMeal,
        allMeals: initialState.allMeals,
      };
    case actions.INIT_NEW_MEAL:
      return {
        ...state,
        menupartId: action.payload.id,
      };

    default:
      return state;
  }
}

export default reducer;
