import { useMediaQuery } from "@mui/material";
import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Infos from "./Infos/Infos";
import Nav from "./Nav/Nav";

import "./sideBar.scss";

function Sidebar() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div className="sidebar__container">
      <Header />
      <Nav />
      {isMobile ? null : <Infos />}
      {isMobile ? null : <Footer />}
    </div>
  );
}

export default Sidebar;
