/* eslint-disable react/jsx-no-bind */

import React, { useEffect, useState } from "react";
import { Box, styled, keyframes, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import BookingForm from "../../../UserComponents/BookingForm/CreateResa";
import "./modalmenu.scss";

// Animations pour l'ouverture et la fermeture de la modal
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

// Styled component pour la modal avec animation conditionnelle
const ModalBox = styled(Box)(({ isVisible }) => ({
  position: "fixed",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "5",
  backdropFilter: "blur(5px)",
  animation: `${isVisible ? slideOut : slideIn} 0.5s ease forwards`,
}));

function ModalMenu({ buttonOpen, modalElement }) {
  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // Fonction pour ouvrir la modal avec animation
  function open() {
    modalElement.current.style.display = "block";
    setIsVisible(true);
  }

  // Fonction pour fermer la modal avec animation
  function close() {
    setIsVisible(false);
    modalElement.current.style.display = "none";
  }

  useEffect(() => {
    // Associe l'ouverture de la modal au bouton
    buttonOpen.current.onclick = open;
  }, [buttonOpen]);

  return (
    <ModalBox className="modal-overlay" onClick={close} ref={modalElement}>
      <nav className="nav__mobile-container">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `nav__mobile-container-link ${
              isActive ? "nav__mobile-container-link--active" : ""
            }`
          }
        >
          <p>Accueil</p>
        </NavLink>
        <NavLink
          to="/drinks"
          className={({ isActive }) =>
            `nav__mobile-container-link ${
              isActive ? "nav__mobile-container-link--active" : ""
            }`
          }
        >
          <p>Nos Boissons</p>
        </NavLink>
        <NavLink
          to="/events"
          className={({ isActive }) =>
            `nav__mobile-container-link ${
              isActive ? "nav__mobile-container-link--active" : ""
            }`
          }
        >
          <p>Evènements et concerts</p>
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            `nav__mobile-container-link ${
              isActive ? "nav__mobile-container-link--active" : ""
            }`
          }
        >
          <p>Nous contacter</p>
        </NavLink>
        <Button
          sx={{
            mt: "2rem",
            backgroundColor: "#FFFDDE",
            color: "#333333",
            border: "none",
            borderRadius: "10px",
            padding: "1rem 2rem",
            boxShadow:
              "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
          }}
          onClick={() => setOpenModal(true)}
        >
          Réserver en ligne
        </Button>
        <BookingForm open={openModal} onClose={() => setOpenModal(false)} />
      </nav>
    </ModalBox>
  );
}

export default ModalMenu;
