import React from "react";
import { ExpandLess } from "@mui/icons-material";
import { IconButton, useMediaQuery, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import useScrollToTop from "../../../hooks/useScrollToTop";
import SideBar from "../SideBar/SideBar";
import Footer from "../SideBar/Footer/Footer";
import Infos from "../SideBar/Infos/Infos";
import SnackbarComponent from "../SnackbarComponent";
import { actionCloseSnackbar } from "../../../redux/actions";

import "./main.scss";

function Main({ children }) {
  const { showTopBtn, goToTop } = useScrollToTop();
  const dispatch = useDispatch();
  const { open, onClose, severity, alertMessage } = useSelector(
    (state) => state.message
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleCloseSnackbar = () => {
    dispatch(actionCloseSnackbar());
  };

  return (
    <Box
      sx={{
        display: isMobile ? "block" : "flex",
        width: "100vw",
      }}
    >
      {showTopBtn && isMobile && (
        <IconButton
          sx={{
            color: "#000",
            position: "fixed",
            bottom: "40px",
            right: "10px",
            backgroundColor: "#FFF",
            zIndex: 100,
          }}
          onClick={goToTop}
        >
          <ExpandLess />
        </IconButton>
      )}
      <SideBar />
      <Box className="main__container">{children}</Box>
      {isMobile ? (
        <>
          <Infos />
          <Footer />
        </>
      ) : null}
      <SnackbarComponent
        open={open}
        onClose={handleCloseSnackbar}
        severity={severity}
        alertMessage={alertMessage}
      />
    </Box>
  );
}

export default Main;
