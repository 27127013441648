import { Box, CardMedia, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import ReactPlayer from "react-player";
import Main from "../../common/Main/Main";

import HomePageEvents from "../EventConcertPage/HomePageEvents";
import "./homepage.scss";

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  border: `8px solid rgba(0, 0, 0, 0.9)`,
  borderRadius: "10px",
  boxShadow: "0px 22px 6px -7px rgba(0, 0, 0, 0.74)",
  width: theme.breakpoints.down("md") ? "50%" : "20%",
}));

function HomePage() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Helmet>
        <title>Maëly&apos;s Café - Accueil</title>
        <meta
          name="description"
          content="Home page to know the daily menus as well as the events of the week at the Maely's Cafe bar"
        />
        <link rel="canonical" href="/home" />
      </Helmet>
      <Main>
        <Box>
          <Box
            sx={{
              margin: "2rem 0.5rem",
              display: "flex",
              justifyContent: isMobile ? "space-around" : "space-between",
              height: "20em",
              flexWrap: isMobile ? "wrap" : "nowrap",
            }}
          >
            <StyledCardMedia
              image="/slider/IMG_5408.webp"
              title="pub"
              sx={{ display: isMobile ? "none" : "block" }}
            />
            <StyledCardMedia image="/slider/IMG_5407.webp" title="pub" />
            <StyledCardMedia
              image="/slider/IMG_5411.webp"
              title="pub"
              sx={{ display: isMobile ? "none" : "block" }}
            />
            <StyledCardMedia image="/slider/IMG_5410.webp" title="pub" />
          </Box>
          <Box
            sx={{
              margin: "auto",
              width: "80%",
              border: `8px solid rgba(0, 0, 0, 0.9)`,
              borderRadius: "10px",
              boxShadow: "0px 22px 6px -7px rgba(0, 0, 0, 0.74)",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
              }}
            >
              <ReactPlayer
                url="/maelys_video.mp4"
                playing
                loop={!isMobile}
                muted
                controls
                width="100%"
                height="100%"
              />
            </Box>
          </Box>
          <HomePageEvents />
        </Box>
      </Main>
    </>
  );
}
export default HomePage;
