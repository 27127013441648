import { combineReducers } from "redux";

import cardReducer from "./cardReducer";
import eventReducer from "./eventReducer";
import loginReducer from "./loginReducer";
import mealReducer from "./mealReducer";
import menupartReducer from "./menupartReducer";
import messageReducer from "./messageReducer";
import modalReducer from "./modalReducer";
import photoReducer from "./photoReducer";
import userReducer from "./userReducer";
import reservationReducer from "./reservationReducer";

export default combineReducers({
  card: cardReducer,
  event: eventReducer,
  login: loginReducer,
  meal: mealReducer,
  menupart: menupartReducer,
  message: messageReducer,
  modal: modalReducer,
  photo: photoReducer,
  user: userReducer,
  reservation: reservationReducer,
});
