import apiAxios from "./index";

/**
 * To login website
 * @param {string} email The email to send
 * @param {string} password The password to send
 * @returns JSON object
 */
export async function requestLogin(email, password) {
  try {
    const response = await apiAxios.post("/auth", {
      email,
      password,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * To check token
 * @param {string} token The token to check
 * @returns JSON object
 */
export async function requestCheck(token) {
  try {
    const response = await apiAxios.post(
      "/checktoken",
      {},
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
