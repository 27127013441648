/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  name: "",
  description: "",
  ordinal: 0,
  cart_id: 0,
  oneMenupart: {},
  allMenuparts: [],
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_MENUPART_INPUT:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case actions.SET_ALL_MENUPARTS:
      return {
        ...state,
        allMenuparts: action.payload,
      };
    case actions.SET_ONE_MENUPART:
      return {
        ...state,
        oneMenupart: action.payload,
      };
    case actions.SET_MENUPART_INFOS:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        description: action.payload.description,
        ordinal: action.payload.ordinal,
        cart_id: action.payload.cart_id,
      };
    case actions.RESET_MENUPART:
      return {
        ...state,
        id: initialState.id,
        name: initialState.name,
        description: initialState.description,
        ordinal: initialState.ordinal,
        cart_id: initialState.cart_id,
        oneMenupart: initialState.oneMenupart,
        allMenuparts: initialState.allMenuparts,
      };

    case actions.RESET_MENUPART_INPUT:
      return {
        ...state,
        id: initialState.id,
        name: initialState.name,
        description: initialState.description,
        ordinal: initialState.ordinal,
      };

    default:
      return state;
  }
}

export default reducer;
