import { useState, useEffect } from "react";

export default function useScrollToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const handleShowButton = () => {
    if (window.scrollY > 150) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleShowButton);
    return () => window.removeEventListener("scroll", handleShowButton);
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return { showTopBtn, goToTop };
}
