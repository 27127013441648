/* eslint-disable no-unused-vars */
import {
  Box,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import ArticleEvent from "../ArticleEvent/ArticleEvent";
import { getEvents } from "../const";
import MobileEventPage from "./MobileEventPage";

const CustomStepperStyle = styled(MobileStepper)`
  background-color: transparent;
  color: #fff;
  & .MuiMobileStepper-dotActive {
    background-color: #fff;
  }
`;

function HomePageEvents() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [weekEvent, setWeekEvent] = useState([]);
  const [title, setTitle] = useState("");

  const maxSteps = weekEvent.length;

  const getWeekEvents = async () => {
    const resultEvent = await getEvents(true);
    setTitle(resultEvent.title);
    setWeekEvent(resultEvent.events);
  };
  useEffect(() => {
    getWeekEvents();
  }, []);

  return (
    <Box sx={{ my: "1rem" }}>
      <Typography variant="h2">{title}</Typography>
      {weekEvent.length > 0 && (
        <>
          {isMobile ? (
            <MobileEventPage weekEvents={weekEvent} />
          ) : (
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {weekEvent.map((item) => (
                <ArticleEvent
                  image={item.photos ? item.photos[0] : null}
                  date={item.date}
                  title={item.title}
                  group={item.group}
                  description={item.description}
                  key={item.id}
                  id={item.id}
                />
              ))}
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}
export default HomePageEvents;
