import { createStore, applyMiddleware, compose } from "redux";
import cardMiddleware from "../middlewares/cardMiddleware";
import eventMiddleware from "../middlewares/eventMiddleware";
import loginMiddleware from "../middlewares/loginMiddleware";
import mealMiddleware from "../middlewares/mealMiddleware";
import menupartMiddleware from "../middlewares/menupartMiddleware";
import photoMiddleware from "../middlewares/photoMiddleware";
import reservationMiddleware from "../middlewares/reservationMiddleware";
import reducer from "../reducers";

const allMiddlewares = applyMiddleware(
  cardMiddleware,
  eventMiddleware,
  loginMiddleware,
  mealMiddleware,
  menupartMiddleware,
  photoMiddleware,
  reservationMiddleware
);

//  to use redux dev tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allMiddlewaresWithReduxDevTools = composeEnhancers(allMiddlewares);

const store = createStore(reducer, allMiddlewaresWithReduxDevTools);

export default store;
