/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  event_id: 0,
  oneReservation: {},
  allReservations: [],
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ALL_RESERVATIONS:
      return {
        ...state,
        allReservations: action.payload,
      };
    case actions.SET_ONE_RESERVATION:
      return {
        ...state,
        oneReservation: action.payload,
      };

    case actions.RESET_RESERVATION:
      return {
        ...state,
        id: initialState.id,
        firstname: initialState.firstname,
        lastname: initialState.lastname,
        email: initialState.email,
        phone: initialState.phone,
        event_id: initialState.event_id,
        oneReservation: initialState.oneReservation,
        AllReservations: initialState.allReservations,
      };

    default:
      return state;
  }
}

export default reducer;
