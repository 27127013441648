/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  id: 0,
  name: "",
  email: "",
  role: "",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USER_INFORMATION:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        role: action.payload.role,
      };

    case actions.DELETE_USER_INFORMATION:
      return {
        ...state,
        id: initialState.id,
        name: initialState.name,
        email: initialState.email,
        role: initialState.role,
      };
    default:
      return state;
  }
}

export default reducer;
