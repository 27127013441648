/* eslint-disable default-param-last */
import * as actions from "../actions";

const initialState = {
  open: false,
  onClose: () => {},
  severity: "success",
  alertMessage: "",
};

/**
 * function used to call action as appropriate
 * @param {object} state redux state
 * @param {object} action redux actions
 * @returns a new state
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        severity: action.payload.severity,
        alertMessage: action.payload.alertMessage,
      };
    case actions.CLOSE_SNACKBAR:
      return {
        ...state,
        open: initialState.open,
        onClose: initialState.onClose,
        severity: initialState.severity,
        alertMessage: initialState.alertMessage,
      };

    default:
      return state;
  }
}

export default reducer;
