import { Delete, Close } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  CardMedia,
  Badge,
  Backdrop,
  CircularProgress,
  styled,
  useMediaQuery,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCreateEvent,
  actionRemovePhotoFromFirebase,
  actionResetEvent,
  actionResetPhoto,
  actionSetPhotoInfos,
  actionUpdateEventInput,
  actionUpdateEvent,
  actionDeleteEvent,
  actionOpenSnackbar,
} from "../../../redux/actions";
import FileInput from "../FileInput/FileInput";
import ConfirmModal from "./ConfirmModal";
import { EventSchema } from "../../../utils/schemas";

const style = {
  width: "100%",
  height: "100%",
  backdropFilter: "blur(4px)",
  px: 4,
  pt: 4,
  pb: 2,
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
};

function EventModal({ open, event, onClose, handleDeletePhoto }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const photo = useSelector((state) => state.photo);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInputPhotoDisabled, setIsInputPhotoDisabled] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({ resolver: yupResolver(EventSchema), mode: "onTouched" });

  useEffect(() => {
    if (event) {
      setValue("id", event.id);
      setValue("title", event.title);
      setValue("date", event.date);
      setValue("description", event.description);
      if (event.photos?.length > 0) {
        dispatch(actionSetPhotoInfos(event.photos[0]));
      }
    } else {
      reset();
      setValue("date", null);
      dispatch(actionResetPhoto());
    }
  }, [event]);

  useEffect(() => {
    if (photo?.title) {
      setIsInputPhotoDisabled(true);
    } else {
      setIsInputPhotoDisabled(false);
    }
  }, [photo]);

  const handleImageState = async (fileName, fileUrl) => {
    try {
      setLoading(true);
      const data = {
        id: 0,
        title: fileName,
        url: fileUrl,
        event_id: event?.id ?? null,
      };
      dispatch(actionSetPhotoInfos(data));
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
    setOpenConfirmModal(false);
  };

  const handleDeleteEvent = async () => {
    try {
      setLoading(true);
      await dispatch(actionDeleteEvent(event.id));
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (event) {
        await dispatch(actionUpdateEvent(data));
      } else {
        await dispatch(actionCreateEvent(data));
      }
      handleClose();
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.8)",
          backdropFilter: "blur(5px)",
          position: "relative",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
          height: "80dvh",
          width: isMobile ? "100%" : "80%",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={(theme) => ({
          zIndex: 2000,
          position: "absolute",
          right: 4,
          top: 4,
          color: "#fffde7",
        })}
      >
        <Close />
      </IconButton>
      <DialogContent sx={style}>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {!event ? "Créer un évènement" : "Modifier un évènement"}
        </Typography>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            gap: 1,
            pt: "1rem",
            height: "90%",
          }}
        >
          <TextField
            size="small"
            color="#fffde7"
            label="Titre de l'évènement"
            placeholder="Titre de l'évènement"
            error={Boolean(errors.title)}
            helperText={errors.title?.message}
            {...register("title")}
          />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Controller
              name="date"
              control={control}
              defaultValue={null}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateTimePicker
                  label="Date de l'évènement"
                  value={value ? moment(value) : null}
                  onChange={(newDate) => {
                    onChange(newDate ? newDate.toISOString(true) : null);
                  }}
                  inputFormat="DD-MM-YYYY HH:mm"
                  timeSteps={{ minutes: 60 }}
                  disablePast
                  slotProps={{
                    day: {
                      sx: {
                        color: "#fffde7",
                      },
                    },
                    openPickerIcon: {
                      sx: { color: "#fffde7" },
                    },
                    layout: {
                      sx: {
                        background: "rgba(0, 0, 0, 0.8)",
                      },
                    },
                    textField: {
                      color: "#fffde7",
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message || "",
                      size: "small",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            size="small"
            color="#fffde7"
            label="Description de l'évènement"
            multiline
            placeholder="Description de l'évènement"
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            {...register("description")}
          />
          {photo?.title ? (
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{
                margin: "auto",
                width: isMobile ? "90%" : "40%",
                height: isMobile ? "60%" : "50%",
              }}
              badgeContent={
                <IconButton type="button" onClick={handleDeletePhoto}>
                  <Delete fontSize="small" sx={{ color: "#fffde7" }} />
                </IconButton>
              }
            >
              <CardMedia
                image={photo.url}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Badge>
          ) : (
            <FileInput
              name="image"
              label="Ajouter une image"
              handleInputState={handleImageState}
              setEventIsDisabled={setIsDisabled}
              accept="image/*,.pdf"
              isInputDisabled={isInputPhotoDisabled}
              setLoading={setLoading}
            />
          )}
          <Stack direction="row" justifyContent="space-between" sx={{ gap: 2 }}>
            {event && (
              <Button
                sx={{
                  flexGrow: 1,
                  color: "#fffde7",
                  backgroundColor: "rgb(167 17 55)",
                  "&:hover": {
                    transform: "scale(1.1)",
                    color: "rgba(255, 255, 255,1)",
                    fontWeight: "bold",
                  },
                }}
                disabled={isDisabled}
                type="button"
                onClick={() => setOpenConfirmModal(true)}
              >
                Supprimer
              </Button>
            )}
            <Button
              sx={{
                flexGrow: 1,
                color: "#fffde7",
                backgroundColor: "rgba(94, 94, 94, 0.865)",
                "&:hover": {
                  transform: "scale(1.1)",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "bold",
                  backgroundColor: "rgba(255, 255, 255,1)",
                },
              }}
              disabled={isDisabled}
              type="submit"
            >
              {!event ? "Ajouter" : "Modifier"}
            </Button>
          </Stack>
        </Stack>
        <Backdrop
          open={loading}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress color="#fffde7" />
        </Backdrop>
      </DialogContent>
      {event && (
        <ConfirmModal
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          handleDelete={handleDeleteEvent}
          title="Supprimer l'évènement"
          message="Êtes-vous sûr de vouloir supprimer cet évènement?"
        />
      )}
    </Dialog>
  );
}

export default EventModal;
