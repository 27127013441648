import { yupResolver } from "@hookform/resolvers/yup";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actionOpenSnackbar } from "../../../../redux/actions";
import {
  getOneReservation,
  updateReservation,
  getAvailableSeats,
} from "../../../../utils/requests/reservationRequest";
import { getBookingSchema } from "../../../../utils/schemas";
import Main from "../../../common/Main/Main";
import { getTime, getTableHour } from "../const";
import { getFrFormat } from "../../../../utils/dates/dates";

function UpdateResaPage() {
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventDate, setEventDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableSeats, setAvailableSeats] = useState(0);
  const bookingSchema = getBookingSchema(availableSeats);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(bookingSchema),
    mode: "onTouched",
    defaultValues: {
      date: eventDate || null,
    },
  });

  const getReservation = async () => {
    setLoading(true);
    if (reservationId) {
      try {
        const response = await getOneReservation(reservationId);
        if (response.status === 200) {
          setValue("firstname", response.data.firstname);
          setValue("lastname", response.data.lastname);
          setValue("email", response.data.email);
          setValue("phone", response.data.phone);
          setValue("seat", response.data.seat);
          setValue("date", response.data.date);
          setEventDate(response.data.date);
        }
      } catch (error) {
        dispatch(
          actionOpenSnackbar({
            severity: "error",
            alertMessage:
              "Une erreur s'est produite, la réservation n'a pas pu être trouvée.",
          })
        );
        reset();
        navigate("/", { replace: true });
      } finally {
        setLoading(false);
      }
    }
  };
  const fetchAvailableSeats = async (date) => {
    setLoading(true);
    const response = await getAvailableSeats(date);
    if (response.status === 200) {
      setAvailableSeats(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (eventDate !== null) fetchAvailableSeats(eventDate);
  }, [eventDate]);

  useEffect(() => {
    getReservation();
  }, [reservationId]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await updateReservation(reservationId, data);
      if (response.status === 200) {
        dispatch(
          actionOpenSnackbar({
            severity: "success",
            alertMessage:
              "Modification enregistrée avec succès! Un email de confirmation a été envoyé à l'adresse email renseignée.",
          })
        );
        reset();
        navigate("/", { replace: true });
      }
    } catch (error) {
      dispatch(
        actionOpenSnackbar({
          severity: "error",
          alertMessage: error.message || "Une erreur s'est produite",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <Box
        maxWidth="sm"
        sx={{
          margin: "auto",
          padding: "1rem",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(5px)",
          position: "relative",
        }}
      >
        <Box>
          <Typography variant="h4">Modification de la réservation</Typography>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              marginBottom: "1rem",
              "& span": {
                fontWeight: 700,
              },
            }}
          >
            Pour le <span>{getFrFormat(eventDate)}</span>
          </Typography>
          {availableSeats && (
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "700",
                my: "1rem",
                mx: "auto",
                width: "100%",
                textAlign: "center",
              }}
            >
              Places disponibles : <strong>{availableSeats}</strong>
            </Typography>
          )}
        </Box>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ gap: 4 }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <Controller
              name="date"
              control={control}
              defaultValue={eventDate}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="Date de l'évènement"
                  value={value ? moment(value) : null}
                  onChange={(date) => {
                    const goodDate = getTime(date);
                    setEventDate(goodDate);
                    setValue("date", goodDate || null); // Mettre à jour le champ date immédiatement
                    onChange(goodDate || null);
                  }}
                  inputFormat="DD-MM-YYYY"
                  disablePast
                  slotProps={{
                    day: {
                      sx: {
                        color: "#fffde7",
                      },
                    },
                    openPickerIcon: {
                      sx: { color: "#fffde7" },
                    },
                    layout: {
                      sx: {
                        background: "rgba(0, 0, 0, 0.8)",
                      },
                    },
                    textField: {
                      color: "#fffde7",
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message || "",
                      size: "small",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            InputLabelProps={{ shrink: true }}
            color="#fffde7"
            label="Prénom"
            type="text"
            placeholder="Prénom"
            error={Boolean(errors.firstname)}
            helperText={errors.firstname?.message}
            {...register("firstname")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            color="#fffde7"
            label="Nom"
            type="text"
            placeholder="Nom"
            error={Boolean(errors.lastname)}
            helperText={errors.lastname?.message}
            {...register("lastname")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            color="#fffde7"
            label="Email"
            type="text"
            placeholder="Email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            {...register("email")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            color="#fffde7"
            label="Numéro de téléphone"
            type="tel"
            placeholder="0612345678"
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            {...register("phone")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            color="#fffde7"
            label="Nombre de personnes"
            type="number"
            placeholder="Nombre de personnes"
            error={Boolean(errors.seat)}
            helperText={errors.seat?.message}
            {...register("seat")}
          />
          <Typography variant="subtitle2">{getTableHour(eventDate)}</Typography>
          <Typography variant="subtitle2">
            **Vos informations personnelles ne seront jamais diffusées et seront
            supprimées le lendemain de la date de réservation.
          </Typography>
          <Button
            type="submit"
            disabled={loading}
            sx={{
              color: "#fffde7",
              backgroundColor: "rgba(255,253,231,0.2)",
              "&:hover": {
                textShadow:
                  "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
              },
            }}
          >
            Modifier
          </Button>
        </Stack>
        <Backdrop
          open={loading}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress color="#fffde7" />
        </Backdrop>
      </Box>
    </Main>
  );
}

export default UpdateResaPage;
