import { useMediaQuery, Typography } from "@mui/material";
import React from "react";
import Article from "../Article/Article";
import "./menupart.scss";

function MenuPart({ menupart, index }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isOdd = (num) => num % 2 === 0;
  return (
    <section className="menupart">
      <Typography variant="h4" className="menupart-title">
        {menupart.name}
      </Typography>
      <div
        className={
          !isOdd(index) || isMobile
            ? "menupart-container"
            : "menupart-container-reverse"
        }
      >
        {menupart.description && <p>{menupart.description}</p>}
        {menupart.photos[0] ? (
          <img
            src={menupart.photos[0].url}
            alt={menupart.photos[0].title}
            className="menupart-photo"
          />
        ) : null}
        <div
          className={
            !isMobile ? "menupart-ComputerContent" : "menupart-content"
          }
        >
          {menupart.meals
            .sort((a, b) => a.id - b.id)
            .map((meal) => (
              <Article
                title={meal.title}
                quarterprice={meal.quarterprice}
                halfprice={meal.halfprice}
                literprice={meal.literprice}
                price={meal.price}
                description={meal.description}
                key={meal.id}
              />
            ))}
        </div>
      </div>
    </section>
  );
}
export default MenuPart;
