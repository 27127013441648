import apiAxios from "./index";

/**
 * function used to make a http request to get all menuparts
 * @returns response with menuparts in an array of object
 */
export async function getAllMenuparts() {
  try {
    const response = await apiAxios.get("/menuparts");
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to get one menupart
 * @param {string} id id of the menupart you want to get
 * @returns response with menupart in an object
 */
export async function getOneMenupart(id) {
  try {
    const response = await apiAxios.get(`/menuparts/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to create a menupart
 * @param {object} menupartDatas menupart informations (name)
 * @returns response with new menupart in an object
 */
export async function createMenupart(menupartDatas) {
  try {
    const response = await apiAxios.post("/menuparts", menupartDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to update a menupart
 * @param {string} id id of the menupart you want to update
 * @param {object} menupartDatas menupart informations (name)
 * @returns response with updated menupart in an object
 */
export async function updateMenupart(id, menupartDatas) {
  try {
    const response = await apiAxios.patch(`/menuparts/${id}`, menupartDatas);
    return response;
  } catch (err) {
    return err.response;
  }
}

/**
 * function used to make a http request to delete a cart
 * @param {string} id id of the cart you want to delete
 * @returns response with an empty object
 */
export async function deleteMenupart(id) {
  try {
    const response = await apiAxios.delete(`/menuparts/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
}
