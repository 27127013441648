import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  styled,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import React from "react";

const StyledDialogContent = styled(TableCell)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backdropFilter: "blur(4px)",
  padding: "3rem 1rem",
  color: "#fff",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
  },
}));

function ReservationModal({ open, onClose, reservations }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  if (!open) return null;
  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.8)",
          backdropFilter: "blur(5px)",
          position: "relative",
          border: `8px solid #fffde7`,
          borderRadius: "5px",
          height: "80dvh",
          width: isMobile ? "100%" : "80%",
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={(theme) => ({
          zIndex: 2000,
          position: "absolute",
          right: 4,
          top: 4,
          color: "#fffde7",
        })}
      >
        <Close />
      </IconButton>
      <StyledDialogContent>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Détail des réservations pour le{" "}
          {reservations
            ? moment(reservations[0]?.date).format("DD/MM/YYYY")
            : "Aucune réservation"}
        </Typography>
        <Box
          sx={{
            pt: "1rem",
          }}
        >
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table sx={{ width: "100%" }} size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Nom</StyledTableCell>
                  {!isMobile && (
                    <>
                      <StyledTableCell align="center">Email</StyledTableCell>
                      <StyledTableCell align="center">
                        Téléphone
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell align="center">
                    Nombre de personnes
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ fontSize: "0.5rem" }}>
                {reservations?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell": {
                        fontSize: "1rem",
                      },
                    }}
                  >
                    <StyledTableCell align="center">
                      {row.firstname} {row.lastname}
                    </StyledTableCell>
                    {!isMobile && (
                      <>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.phone}
                        </StyledTableCell>
                      </>
                    )}
                    <StyledTableCell align="center">
                      {row.seat} personnes
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ReservationModal;
