/* eslint-disable camelcase */
import {
  getAllMenuparts,
  getOneMenupart,
  createMenupart,
  updateMenupart,
  deleteMenupart,
} from "../../utils/requests/menupartRequest";
import * as actions from "../actions";

const menupartMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case actions.GET_ALL_MENUPARTS: {
      const response = await getAllMenuparts();
      if (response.status === 200) {
        store.dispatch(actions.actionSetAllMenuparts(response.data));
      }
      return;
    }

    case actions.GET_ONE_MENUPART: {
      const { menupart } = store.getState();
      const response = await getOneMenupart(menupart.id);
      if (response.status === 200) {
        store.dispatch(actions.actionGetOneMenupart(response.data));
        store.dispatch(actions.actionSetMenupartInfos(response.data));
        if (response.data.meals) {
          // TODO action meal
        }
        if (response.data.photos) {
          store.dispatch(actions.actionSetPhotoInfos(response.data.photos[0]));
        }
      }
      return;
    }

    case actions.CREATE_MENUPART: {
      const { photo } = store.getState();
      const menupartDatas = {
        ...action.payload,
        card_id: 3,
      };
      const response = await createMenupart(menupartDatas);
      if (response.status === 200) {
        if (photo.title !== "") {
          await store.dispatch(
            await actions.actionSetPhotoOwnerId("menupart_id", response.data.id)
          );
          await store.dispatch(actions.actionCreatePhoto());
        }
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Catégorie créée avec succès!",
          })
        );
      } else {
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "error",
            alertMessage: response.error.message,
          })
        );
      }
      return;
    }

    case actions.UPDATE_MENUPART: {
      const { photo } = store.getState();
      const { id, ...rest } = action.payload;
      const menupartDatas = {
        ...rest,
        card_id: 3,
      };
      if (photo.id === 0) {
        await store.dispatch(actions.actionCreatePhoto());
      }
      const response = await updateMenupart(id, menupartDatas);
      if (response.status === 200) {
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Catégorie modifiée avec succès!",
          })
        );
      }
      return;
    }

    case actions.DELETE_MENUPART: {
      const { photo } = store.getState();
      if (photo.id) {
        await store.dispatch(actions.actionRemovePhotoFromFirebase());
      }
      const response = await deleteMenupart(action.payload);
      if (response.status === 204) {
        store.dispatch(actions.actionResetMenupart());
        store.dispatch(actions.actionGetOneCard());
        store.dispatch(
          actions.actionOpenSnackbar({
            severity: "success",
            alertMessage: "Catégorie supprimée avec succès!",
          })
        );
      }
      return;
    }

    default:
      next(action);
  }
};

export default menupartMiddleware;
