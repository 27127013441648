import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import BookingForm from "../../../UserComponents/BookingForm/CreateResa";
import "./nav.scss";

function Nav() {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <nav className="nav__container">
      <NavLink
        to="/"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Accueil</p>
      </NavLink>
      <NavLink
        to="/drinks"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nos Boissons</p>
      </NavLink>
      <NavLink
        to="/events"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Evènements et concerts</p>
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) =>
          `nav-link ${isActive ? "nav-link--active" : ""}`
        }
      >
        <p>Nous contacter</p>
      </NavLink>
      <Button
        sx={{
          mt: "2rem",
          backgroundColor: "#FFFDDE",
          color: "#333333",
          border: "none",
          borderRadius: "10px",
          padding: "1rem 2rem",
          boxShadow:
            "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
        }}
        onClick={() => setOpenModal(true)}
      >
        Réserver en ligne
      </Button>
      <BookingForm open={openModal} onClose={() => setOpenModal(false)} />
    </nav>
  );
}
export default Nav;
