/* eslint-disable no-unused-vars */
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  MobileStepper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ArticleEvent from "../../ArticleEvent/ArticleEvent";

const CustomStepperStyle = styled(MobileStepper)`
  background-color: transparent;
  color: #fff;
  & .MuiMobileStepper-dotActive {
    background-color: #fff;
  }
`;

function MobileEventPage({ weekEvents }) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = weekEvents.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        heigth: "100%",
      }}
    >
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        slideStyle={{ padding: 10, width: "100%", height: "100%" }}
      >
        {weekEvents.map((item) => (
          <ArticleEvent
            key={item.id}
            id={item.id}
            image={item.photos[0] ? item.photos[0] : null}
            date={item.date}
            title={item.title}
            group={item.group}
            description={item.description}
          />
        ))}
      </SwipeableViews>
      <CustomStepperStyle
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{ color: "#fff", fontSize: "1rem", fontWeight: "bold" }}
          >
            Suiv.
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{ color: "#fff", fontSize: "1rem", fontWeight: "bold" }}
          >
            <KeyboardArrowLeft />
            Préc.
          </Button>
        }
      />
    </Stack>
  );
}

export default MobileEventPage;
