import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../UserComponents/HomePage/HomePage";
import EventConcertPage from "../UserComponents/EventConcertPage/EventConcertPage";
import ContactPage from "../UserComponents/ContactPage/ContactPage";
import ConfPage from "../UserComponents/ConfPol/ConfPol";
import NotFoundPage from "../common/NotFoundPage/NotFoundPage";
import RequireAuth from "../AdminComponents/RequireAuth/RequireAuth";
import LoginPage from "../AdminComponents/LoginPage/LoginPage";
import AdminHomePage from "../AdminComponents/AdminHomePage/AdminHomePage";
import ResaPage from "../AdminComponents/ResaPage";
import EventPage from "../AdminComponents/EventPage/EventPage";
import MenuPage from "../AdminComponents/MenuPage/MenuPage";
import CardContainer from "../../container/CardContainer";
import CartePage from "../UserComponents/CartePage/CartePage";
import UpdateResaPage from "../UserComponents/BookingForm/UpdateResaPage";
import DeleteResaPage from "../UserComponents/BookingForm/DeleteResaPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/drinks" element={<CartePage cardType="drink" />} />
      <Route path="/events" element={<EventConcertPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/update-reservation/:reservationId"
        element={<UpdateResaPage />}
      />
      <Route
        path="/delete-reservation/:reservationId"
        element={<DeleteResaPage />}
      />
      <Route path="/politiques-confidentialite" element={<ConfPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/admin" element={<RequireAuth />}>
        <Route path="home" element={<AdminHomePage />} />
        <Route path="event" element={<EventPage />} />
        <Route path="reservation" element={<ResaPage />} />
        <Route path="menus" element={<MenuPage />}>
          <Route path="drink" element={<CardContainer />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
